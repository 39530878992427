<template>
  <div>
<v-card
  style="background-color: white;"
  elevation="3"
  class="my-4 mr-1 py-1 rounded-lg arabic"
>
<v-row class="ma-1" style="display: flex !important;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: flex-end;">
    <v-btn
    style="font-size: medium; opacity: 1; letter-spacing: 0.04cap;"
      v-for="(button, index) in buttons"
      :key="index"
      :value="button.value"
      :class="{'active-button': selectedButton === index}"
      @click="selectButton(button.value , index) ,fetchAllCoupons()"
      text
      class="rounded-0 test py-5"
    >
      <v-icon class="ml-1">
        {{ button.icon }}
      </v-icon>
      {{ button.label }}
      <v-slide-y-transition>
                <div  v-if="selectedButton === index"  class="BtnUnderline" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
    </v-btn>

  </v-row>

</v-card>
<!-- cases row -->
<v-row class="d-flex arabic ma-4" style="direction: rtl; display: flex !important;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: flex-end; min-width: 100%;">
      <v-btn
       @click="filterByCaseStatus('الكل'), fetchAllCoupons()"
      color="#4378a0"
       dark
       :outlined="selectedCaseStatus !== 'الكل'"
      class="px-7 mx-1 rounded-pill"
      >
        الكل
      </v-btn>
      <v-btn
        color="#4378a0"
        dark
        class="px-7 mx-1 rounded-pill"
        :outlined="selectedCaseStatus !== 'جديد'"
        @click="filterByCaseStatus('جديد'), fetchAllCoupons()"
        :class="{'my-1': $vuetify.breakpoint.smAndDown}"
      >
        جديد
      </v-btn>
      <v-btn
      color="#4378a0"
      dark
      :outlined="selectedCaseStatus !== 'قديم'"
      @click="filterByCaseStatus('قديم'), fetchAllCoupons()"
      class="px-7 mx-1 rounded-pill"
      >
        قديم
      </v-btn>
      <v-btn
      color="#4378a0"
    dark
    :outlined="selectedCaseStatus !== 'قيد العمل'"
    @click="filterByCaseStatus('قيد العمل'), fetchAllCoupons()"
    class="px-7 mx-1 rounded-pill"
      >
        قيد العمل
      </v-btn>
      <v-btn
      color="#4378a0"
    dark
    :outlined="selectedCaseStatus !== 'بيدينج'"
    @click="filterByCaseStatus('بيدينج'), fetchAllCoupons()"
    class="px-7 mx-1 rounded-pill"
      >
        بيدينج
      </v-btn>
      <v-btn
      color="#4378a0"
    dark
    :outlined="selectedCaseStatus !== 'متوقف'"
    @click="filterByCaseStatus('متوقف'), fetchAllCoupons()"
    class="px-7 mx-1 rounded-pill"
      >
        متوقف
      </v-btn>

      <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical class="border-opacity-25"></v-divider>
      <v-btn
        color="#4378a0"
        class="px-7 mx-1 rounded-pill"
        @click="filterByCaseStatus('الكوبونات المشتركة'), fetchAllCoupons()"
        :outlined="selectedCaseStatus !== 'الكوبونات المشتركة'"
        dark
      >
        الكوبونات المشتركة
      </v-btn>
    </v-row>

      <v-card class="mr-2 rounded-lg" elevation="5">

      <v-card flat class="pa-5 mt-5 rounded-lg" style="background-color: white;">
      <v-row class="d-flex flex-row justify-space-around align-center">
      <v-col cols="12" md="2"  class="d-flex flex-column arabic algin-center justify-center" style="align-items: center;">
         <v-card-title class="arabic-bold text-center ma-0 pa-0"  style="color: #4378a0; font-size: x-large;">
          <img class="ml-3 rotate" src="../assets/CouponCircle.png" alt="" width="25%">
          الكوبونات
         </v-card-title>
         </v-col>
        <v-col cols="12" md="6" style="align-items: center;">
         <v-row class="d-flex flex-row align-center">
              <v-col cols=4>
          <v-card flat class="dotted-border-card3 text-center rounded-lg d-flex flex-column align-center justify-center" :class="{'pa-0': $vuetify.breakpoint.smAndDown , 'pa-3': $vuetify.breakpoint.mdAndUp}">
                <v-card-title class="arabic pa-0 text-center"
                style="color: #4378a0;"
                      :style="{
                      'font-size': $vuetify.breakpoint.smAndDown ? 'small' : 'medium',
                        }">
          عدد الكوبونات
                </v-card-title>
                <v-card-text class="arabic-bold pa-0" style="font-size: 1.3rem; color: #4378a0;">
          {{ couponsCounter }}
                </v-card-text>
              </v-card>
              </v-col>
              <v-col cols=4>
          <v-card flat class="dotted-border-card2 text-center rounded-lg d-flex flex-column align-center justify-center" :class="{'pa-0': $vuetify.breakpoint.smAndDown , 'pa-3': $vuetify.breakpoint.mdAndUp}">
                <v-card-title class="arabic pa-0" style="color: #4378a0;"
                 :style="{
                      'font-size': $vuetify.breakpoint.smAndDown ? 'small' : 'medium',
                        }">
          عدد المتاجر
                </v-card-title>
                <v-card-text class="arabic-bold pa-0" style="font-size: 1.3rem; color: #4378a0;">
                  {{ storesCounter }}
                </v-card-text>
              </v-card>
              </v-col>
              <v-col cols=4>
          <v-card flat class="dotted-border-card1 text-center rounded-lg d-flex flex-column align-center justify-center" :class="{'pa-1': $vuetify.breakpoint.smAndDown , 'pa-3': $vuetify.breakpoint.mdAndUp}">
                <v-card-title class="arabic pa-0" style="color: #4378a0;"
                 :style="{
                      'font-size': $vuetify.breakpoint.smAndDown ? 'small' : 'medium',
                        }">
          عدد الشركات
                </v-card-title>
                <v-card-text class="arabic-bold pa-0"  style="font-size: 1.3rem; color: #4378a0;">
         {{ companiesCounter }}
                </v-card-text>
              </v-card>
                </v-col>
            </v-row>
        </v-col>
        <!-- إضافة كوبونات -->
      <v-col cols=12 md="3" class="d-flex flex-column arabic">
        <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#4378a0" flat class="arabic mx-1 white--text" style="letter-spacing: 0.03cap; font-size: 17px; height: 40px"
          v-bind="attrs"
          v-on="on"
        >
        إضافة كوبونات جديدة <v-icon class="mr-1">
          mdi-plus</v-icon>
      </v-btn>
      </template>
      <v-card
      class="arabic-bold"
      color="white"
      >
      <v-stepper
      flat
      v-model="e6"
      vertical
      class="arabic"
      color="#4378a0"
  >
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
      editable
      color="#4378a0"
      class="arabic"
      style="font-size: large;"
    >
    إضافة كوبونات جديدة
    </v-stepper-step>
    <v-stepper-content step="1">
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
              label=" كود الكوبون*"
              required
              v-model="newCoupon"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
                style="font-size: small"
                color="#4378a0"
                class="arabic"
                label="المتجر"
                :items="stores"
                item-text="name_ar"
                item-value="id"
                v-model="selectedStore"
                @change="GetStoreCountries(selectedStore)"
              >
              <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchStore"
                    placeholder="Search"
                    @input="searchStorefun"
                    solo
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
             </template>
            </v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
            style="font-size: small"
            color="#4378a0"
              class="arabic"
              label="الشركة"
              :items=companies
                item-text="name_ar"
                item-value="id"
                v-model="selectedCompany"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
            style="font-size: small"
            color="#4378a0"
              class="arabic"
              label="الدول"
              multiple
              :items=countries
              item-text="name_ar"
              item-value="id"
              v-model="selectedCountries"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
              label="عنوان عربي"
              v-model="ar_name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
              label="عنوان انجليزي"
              v-model="en_name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-text-field
              v-model="discount"
               color="#4378a0"
              label="نسبة الخصم"
              type="number"
              min="0"
              max="100"
               @blur="setDefaultDiscount"
              suffix="%"
              :disabled="free_shipping"
              class="arabic"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="8"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
                label="تفاصيل الكوبون"
                v-model="NewCouponDetails"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-switch
              v-model="free_shipping"
              label="شحن مجاني؟"
              color="#4378a0"
              class="arabic"
            ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-switch
              v-model="prize"
              label="قسيمة"
              color="#4378a0"
              class="arabic"
            ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="5"
              sm="6"
            >
            <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="#4378a0"
            v-model="date"
            label="تاريخ انتهاء صلاحية الكوبونات"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            color="#4378a0"
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            style="letter-spacing: normal;"
            class="arabic"
            color="#4378a0"
            @click="menu = false"
          >
            إغلاق
          </v-btn>
          <v-btn
            style="letter-spacing: normal;"
            class="arabic white--text"
            color="#4378a0"
            @click="$refs.menu.save(date)"
          >
            حفظ
          </v-btn>
        </v-date-picker>
      </v-menu>
      <br>
      <v-small-text class="arabic">* يمكنك إضافة Bulk في خانة كود الكوبون</v-small-text>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="mt-1 mb-1 align-center">
            <v-col md="2" sm="2">
            <v-btn
            text
            class="arabic"
            style="letter-spacing: 0.05cap; font-size: medium"
              @click="dialog = false"
            ><v-icon class="mr-1 mt-1" size="large">mdi-close-circle-outline</v-icon>إغلاق</v-btn>
          </v-col>
          <v-col md="10" sm="10">
            <v-btn
            class="arabic"
            style="letter-spacing: 0.05cap; font-size: large; background-color: #4378a0; color: white; width: 100%;"
              @click="addNewCoupon"
            >إضافة<v-icon class="mr-1 mt-1" size="large">mdi-plus</v-icon></v-btn>
          </v-col>
          </v-row>
        </v-card-actions>
        </v-stepper-content>
        <v-stepper-step
      :complete="e6 > 2"
      step="2"
      class="arabic"
      color="#4378a0"
    >
     إكمال عملية الإضافة<v-icon class="mr-1" size="x-large" style="opacity: 70%;">mdi-check-all</v-icon>
     <p class="mt-1">حدد الكوبونات التي تريد تحديد أنواعها</p>
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card-text>
          <v-row class="d-flex justify-center" dense>
            <v-col class="mr-n3" cols="12" md="10" sm="12">
         <v-select
            style="font-size: small"
            color="#4378a0"
            btns
            class="arabic ma-auto"
            label="الكوبونات المضافة"
            :items=NewAddedCoupons
            multiple
            item-text="code"
            item-value="id">
         </v-select>
            </v-col>
            </v-row>
            <v-row
                v-for="(row, index) in newTypesArray"
                :key="index"
                :style="{
                        'height': $vuetify.breakpoint.smAndDown ? '100%' : '50px',
                    }"
                  class="mx-5 my-0 align-center"
    >
      <v-col cols="12"  md="5">
        <v-select
          :items="getAvailableTypes(index)"
          label="الأنواع"
          color="#4378a0"
          class="arabic mx-4"
          required
          v-model="row.update_type"
          :disabled="isMaxTypesReached && !row.update_type"
        ></v-select>
      </v-col>

      <v-col cols="12"  md="5">
        <v-select
          :items="['بيدينج', 'قيد العمل', 'متوقف اوتوماتيكي', 'متوقف يدوي', 'قديم', 'جديد']"
          label="الحالة"
          color="#4378a0"
          class="arabic mx-4"
          v-model="row.update_status"
          :disabled="!row.update_type"
        ></v-select>
      </v-col>

      <v-col cols="2" class="text-center">
        <!-- Remove button -->
        <v-btn color="red" @click="removeRow(index)" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      color="#4378a0"
      outlined
      class="arabic my-8"
      :disabled="isMaxTypesReached || newTypesArray.length >= 6"
      @click="addRow"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
        </v-card-text>
        <v-row class="mt-1 mb-1 align-center justify-center mt-4">
            <v-btn
            outlined
            class="arabic ma-auto"
            color="#4378a0"
            style="letter-spacing: 0.05cap; font-size: medium"
              @click=couponStepperFinish()
            ><v-icon class="mr-1 mt-1" size="large">mdi-close-circle-outline</v-icon>إغلاق<small v-if="$vuetify.breakpoint.mdAndUp">(سيتم تحويل كل الكوبونات المضافة بشكل تلقائي الى المخزن)</small></v-btn>
        </v-row>
          <v-row class="mt-1 mb-1 align-center justify-center">
          <v-col cols="8">
            <v-btn
            class="arabic ma-auto"
            color="success"
            style="letter-spacing: 0.05cap; font-size: large; color: white; width: 100%;"
              @click =changeNewCouponsTypeStatus
            >إتمام العملية<v-icon class="mt-1 mx-1" size="large">mdi-check</v-icon></v-btn>
          </v-col>
          </v-row>
      </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
      </v-col>

    </v-row>
    <v-row class="d-flex align-center">
        <v-col cols="12" md="12">
      <v-expansion-panels light  flat>
      <v-expansion-panel style="background-color: #f7fbff">
        <v-expansion-panel-header disable-icon-rotate class="arabic-bold justify-start">
              <v-row>
                <span style="color: #5298B6;">فلاتر البحث</span>
                <v-icon class="d-flex justify-start" color="#5298B6">
                mdi-filter
                </v-icon>
              </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4" md="2">
              <v-text-field
                v-model="filterdID"
                color="#4378a0"
                label="ID"
                outlined
                clearable
                class="arabic"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" >
              <v-text-field
                v-model="filterdCode"
                color="#4378a0"
                clearable
                label="الكود"
                outlined
                class="arabic"
             ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                    <v-select
                    outlined
                  style="font-size: small"
                  color="#4378a0"
                  class="arabic ma-auto"
                  label="المتجر"
                  :items="stores"
                  clearable
                  v-model="filterdStore"
                  @change="getCompanyByStore"
                  item-text="name_ar"
                  item-value="id"
                  >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchStore"
                          placeholder="Search"
                          @input="searchStorefun"
                          solo
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
            </v-col>
            <v-col cols="4" md="2">
                  <v-select
                  outlined
                  style="font-size: small"
                  color="#4378a0"
                  class="arabic ma-auto"
                  label="الشركة"
                  clearable
                  :items=companiesDisplay
                  item-text="name_ar"
                  item-value="id"
                  @change="getStoreByCompany"
                  v-model="filterdCompany"
                  >
                  <template v-slot:prepend-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-text-field
                                  v-model="searchCompanies"
                                  placeholder="Search"
                                  @input="searchCompanyfun"
                                  solo
                                ></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
         </v-select>
            </v-col>
            <v-col cols="4" md="2">
                  <v-select
                  outlined
                  style="font-size: small"
                  color="#4378a0"
                  class="arabic ma-auto"
                  label="النوع"
                  clearable
                 :items="['المخزن','إضافة الكروم' ,'التطبيق', 'يلا كوبون', 'بوابة الكوبونات', 'غوغل ادز']"
                 v-model="filterdTypes"
                  >
         </v-select>
            </v-col>
            <v-col cols="4" md="2">
                  <v-select
                  outlined
                  style="font-size: small"
                  color="#4378a0"
                  class="arabic ma-auto"
                  label="حالة المتجر"
                  clearable
                  :items="['نشط بلينك','نشط كوبون' ,'نشط مختلط' ,'متوقف' ,'متوقف نهائي' ,'خامل']"
                  v-model="filtersStoreStatus"
                  >
         </v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>
    <v-data-table
      dense
      checkbox-color="#4378a0"
      :loading="loading"
      :headers="selectedCoupons"
      :items="filteredCouponsBycases"
      :search="searchT"
      :item-value="id"
      :items-per-page="itemsPerPage"
      hide-default-footer
      v-model="selectedCouponRow"
      item-key="id"
      show-select
      :single-select="singleSelect"
      class="arabic mb-16 pb-7"
      @update:page="handlePageChange"
      :style="{
                        'min-width': $vuetify.breakpoint.smAndDown ? '' : '800px',
                        'width': '100%'
                    }"
    >
    <template v-slot:[`item.is_used`]="{ item }">
    <v-icon v-if="item.is_used" color="green">mdi-check</v-icon>
    <v-icon v-else color="red">mdi-close</v-icon>
  </template>
    <template v-slot:[`item.free_shipping`]="{ item }">
    <v-icon v-if="item.free_shipping" color="green">mdi-check</v-icon>
    <v-icon v-else color="red">mdi-close</v-icon>
  </template>
    <template v-slot:[`item.prize`]="{ item }">
    <v-icon v-if="item.prize" color="green">mdi-check</v-icon>
    <v-icon v-else color="red">mdi-close</v-icon>
  </template>
    <template v-slot:[`item.details`]="{ item }">
      <div style="max-width: 100px; word-break:keep-all; white-space: normal;">
      {{ item.details }}
    </div>
  </template>
    <template v-slot:[`item.discount`]="{ item }">
      <div style="">
      {{ item.discount }} %
    </div>
  </template>
  <template v-slot:[`item.moved_to_in_progress_status`]="{ item }">
  <div style="direction: ltr;">
    <div>{{ formatDate(item.moved_to_in_progress_status).date }}</div>
    <div>{{ formatDate(item.moved_to_in_progress_status).time }}</div>
  </div>
</template>
  <template v-slot:[`item.created_at`]="{ item }">
  <div style="direction: ltr;">
    <div>{{ formatDate(item.created_at).date }}</div>
    <div>{{ formatDate(item.created_at).time }}</div>
  </div>
</template>
<template v-slot:[`item.types`]="{ item }">
      <div>
        <div v-for="(type, index) in item.types" :key="type.name">
          {{ type.name }}<span v-if="index < item.types.length - 1">,</span>
        </div>
      </div>
    </template>
    <template v-slot:[`item.types.cases`]="{ item }">
      <div v-for="(type, index) in item.cases" :key="index" class="marmar" style="margin-bottom: 5px !important;">
           <span  :style="{color: getStatusColor(type) }">
              <v-chip
              :color="getStatusColor(type)"
              dark
              style="margin-bottom: 5px !important;"
              x-small
            >
              {{ type }}
            </v-chip>
            </span>
        </div>
  </template>
    <template v-slot:[`item.allCases`]="{ item }">
    <div v-for="caseItem in item.types" :key="caseItem.id">
      <span :style="{ color: getStatusColor(caseItem.cases) }">
              <v-chip
              :color="getStatusColor(caseItem.cases)"
              dark
              small
            >
            {{ caseItem.cases }}
            </v-chip>
            </span>

    </div>
  </template>

  <template v-slot:[`item.actions`]="{ item }" >
    <td style="padding: 0; width: 150px; overflow: visible;" class="text-center">
    <v-speed-dial
      v-model="fabState[item.id]"
       :direction="!$vuetify.breakpoint.smAndDown ? 'right' : 'left'"
      :open-on-hover="true"
      transition="scale-transition"
      style="position: relative; display: inline-block;"
    >
      <template v-slot:activator>
        <v-btn
          fab
          color="#5283A7"
          dark
          elevation="0"
          style="z-index: 10;"
          x-small
        >
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-btn
        x-small
        fab
        color="green darken-2"
        dark
        @click="logCoupon(item.id)"
      >
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
      <v-btn
        x-small
        fab
        color="blue"
        dark
        style="z-index: 100;"
        @click="getEditCopoun(item.id)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        x-small
        fab
        color="red"
        dark
        @click="deleteCoupon(item.id)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>
    </td>
  </template>
  <template v-slot:top>
        <v-row class="d-flex flex-row algin-center justify-center">
          <v-col cols="12" md="2" class="d-flex justify-center" style="align-items: center;">
            <v-dialog
          v-model="dialog1"
          max-width="800"
          style="overflow: hidden !important; font-size: 0.75rem !important"
      >
      <template v-slot:activator="{ on, attrs }">
       <v-btn  v-bind="attrs"
        v-on="on"
         color="#4378a0"
          flat outlined
           class="arabic-bold mr-n8" height="44px" style="letter-spacing: normal; border: solid 2px #4378a0;">
              <v-icon class="mr-1">mdi-magnify</v-icon>
               <span class="ml-3">{{ fieldsVisible ? 'بحث محدد' : 'بحث محدد' }}</span>
            </v-btn>
      </template>
      <v-card
      class="arabic-bold py-6 px-4"
      color="white"
      >
      <v-card-title class="arabic-bold">
      البحث المحدد
      </v-card-title>
      <v-radio-group v-model="selectedSearchStatus" row style="font-size: 0.75rem !important">
                  <v-radio label="جديد" value="جديد" ></v-radio>
                  <v-radio label="قيد العمل" value="قيد العمل"></v-radio>
                </v-radio-group>
                <v-row class="mx-3">
                <v-col cols="12" md="4">
                <v-select
                height="30px"
                  :items="stores"
                  v-model="SelectedSearchStores"
                  item-text="name_ar"
                  item-value="name_ar"
                  label="المتجر"
                  color="#4378a0"
                  class="arabic"
                >
                <template v-slot:prepend-item>
    <v-list-item>
      <v-list-item-content>
        <v-text-field
          v-model="searchStore"
          placeholder="Search"
          @input="searchStorefun"
          solo
        ></v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
  </template>
      </v-select>
                </v-col>
                <v-col cols="12" md="4">
                <v-select
                v-model="selectesSearchType"
                 :items="['المخزن','إضافة الكروم' ,'التطبيق', 'يلا كوبون', 'بوابة الكوبونات', 'غوغل ادز']"
                  label="النوع"
                  color="#4378a0"
                  class="arabic"
                ></v-select>
                </v-col>
                <v-col cols="12" md="1">
                <v-btn color="#4378a0" @click="filterCoupons(); fieldsVisible = !fieldsVisible" text class="arabic-bold mt-4" style="font-size: 1.2rem;">بحث</v-btn>
                </v-col>
              </v-row>
              <div v-if="fieldsVisible" class="mt-4" >
                <v-card-title class="arabic-bold" >
                    تعديل الأكواد
               </v-card-title>
               <v-row class="mx-5">
                 <v-col cols="12" md="6">
                  <v-select
                  v-model="spSelectedCoupons"
                  :items="SpSearchCoupons"
                  multiple
                  item-text="code"
                  item-value="id"
                  label="الاكواد"
                  color="#4378a0"
                  class="arabic"
                ></v-select>
                 </v-col>
               </v-row>
               <v-row
                v-for="(row, index) in newTypesArray"
                :key="index"
                :style="{
                        'height': $vuetify.breakpoint.smAndDown ? '100%' : '50px',
                    }"
                  class="mx-5 my-0align-center"
    >
      <v-col cols="12"  md="5">
        <v-select
          :items="getAvailableTypes(index)"
          label="الأنواع"
          color="#4378a0"
          class="arabic mx-4"
          required
          v-model="row.update_type"
          :disabled="isMaxTypesReached && !row.update_type"
        ></v-select>
      </v-col>

      <v-col cols="12"  md="5">
        <v-select
          :items="['بيدينج', 'قيد العمل', 'متوقف اوتوماتيكي', 'متوقف يدوي', 'قديم', 'جديد']"
          label="الحالة"
          color="#4378a0"
          class="arabic mx-4"
          v-model="row.update_status"
          :disabled="!row.update_type"
        ></v-select>
      </v-col>

      <v-col cols="2" class="text-center">
        <!-- Remove button -->
        <v-btn color="red" @click="removeRow(index)" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      color="#4378a0"
      outlined
      class="arabic my-8"
      :disabled="isMaxTypesReached || newTypesArray.length >= 6"
      @click="addRow"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
                  <v-divider></v-divider>
                  <v-btn @click="changeCouponsTypeStatus" color="#4378a0"  dark class="arabic my-2">
                  تعديل
                  </v-btn>

                </div>
      </v-card>
    </v-dialog>
          </v-col>
          <v-col cols="12" md="6">
            <v-combobox
         style="font-size: small;"
         color="#4378a0"
          small-btns
          clearable
          :items="columns"
          v-model="selectedCoupons"
          deletable-btns
                  class="arabic d-flex justify-center  mt-2"
                  :class="{'mx-5  ': $vuetify.breakpoint.smAndDown , 'mr-n4': $vuetify.breakpoint.mdAndUp}"
                  label="الأعمدة الظاهرة"
                  multiple>
                  <template v-slot:prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
        <v-list-item-action>
                <v-icon :color="likesSomeCoupons ? '#4378a0' : ''">
                  {{ likesAllCoupons ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
              </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="arabic-bold" style="font-size: small; opacity: 80%;">
              تحديد الكل
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-1"></v-divider>
                   </template>
                  <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="arabic">
              لا يوجد خيارات مطابقة.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
                   </template>
         </v-combobox>
           </v-col>
          <v-col cols=12 md="3" class="d-flex flex-column justify-center">
            <v-btn elevation="0"  @click="exportToExcel" v-if="$vuetify.breakpoint.mdAndUp" color="#E2EFE3" flat class="mx-1 mt-1 mb-0 arabic" style="color: #43A047; letter-spacing: 0.05cap;">
              <v-icon class="">mdi-microsoft-excel</v-icon>
              تخريج ملف اكسل
            </v-btn>
            <v-btn elevation="0" @click="fetchAllCoupons()" color="#D7E8E6" flat class="mx-1 mt-2 mb-0 arabic" :class="{'mx-4': $vuetify.breakpoint.smAndDown}"  style="letter-spacing: 0.05cap; color: #00695C">
              <v-icon class="">
                mdi-update
              </v-icon>
            تحديث الجدول</v-btn>
          </v-col>
        </v-row>
    <v-text-field
      class="arabic  my-5"
      :class="{'mx-5': $vuetify.breakpoint.smAndDown , 'mx-13': $vuetify.breakpoint.mdAndUp}"
        v-model="searchT"
        color="#4378a0"
        append-icon="mdi-magnify"
        label="بحث في الجدول"
        single-line
        hide-details
      ></v-text-field>

<!-- when selected in tabel -->
<transition name="slide-down">
        <div  v-if="selectedCouponRow.length > 0">
          <v-row class="">
          <v-col cols="6" sm="12" md="2">
            <p class="arabic-bold pt-2 text-center">
           تغيير الحالة لـ :
            </p>
          </v-col>
          <v-col class="d-flex" style="flex-wrap: wrap" :style="{
                'justify-content': $vuetify.breakpoint.smAndDown ? 'space-between' : 'start',
              }" >
            <v-btn
      v-for="(button, index) in statusButtons"
      :key="index"
      :color="button.color"
      outlined
      class="lg-rounded px-9 mx-1"
      @click="editStatusStop(button.text)"
    >
      {{ button.text }}
      <v-icon class="mr-1">
        {{ button.icon }}
      </v-icon>
    </v-btn>
          </v-col>
                </v-row>
                <v-row class="mx-1">
          <v-col cols="6" md="4">
            <v-btn color="red" outlined class="lg-rounded arabic-bold px-6" @click="deleteCopounEnsure = true">
              حذف العناصر المحددة
              <v-icon class="mr-1">
                mdi-trash-can
              </v-icon>
            </v-btn>
          </v-col>
          </v-row>
        </div>
</transition>
    </template>
    <template v-slot:footer>
      <div v-if="loading" class="d-flex justify-center py-4">
          <v-progress-linear indeterminate color="primary" />
        </div>
        <v-row
        style="direction: rtl;"
          class="mt-2"
          align="center"
          justify="space-between"
        >
          <v-col>
            <span class="grey--text arabic mr-2">عدد الكوبونات في الصفحة</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number) in itemsPerPageArray"
                  :key="number"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col class="d-flex justify-end align-center">
            <span
              class="ml-4
              grey--text"
            >
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              small
              dark
              color="#4378A0"
              class="ml-1"
              :disabled="!formerPage"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="#4378A0"
              class="mx-1"
              :disabled="!nextPageUrl"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
  </v-data-table>
  </v-card>
  <v-snackbar
     v-model="deleteDioalog" class="arabic"
      :timeout="2000"
    >
    تم حذف العناصر المحددة بنجاح !

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          class="arabic"
          v-bind="attrs"
          @click="deleteDioalog = false"
        >
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
  <!-- <v-dialog v-model="deleteDioalog" max-width="600">
              <v-card
                class="d-flex justify-center align-center black--text arabic"
                width="100%"
                style="background: #C6DEDB; opacity: 80%"
              >
                <p class="my-2">
                  تم حذف العناصر المحددة بنجاح !
                </p>
              </v-card>
            </v-dialog> -->
            <v-snackbar
     v-model="editStatusDioalog" class="arabic"
      :timeout="2000"
    >
  تم تعديل حالة الكوبون بنجاح !

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          class="arabic"
          v-bind="attrs"
          @click="editStatusDioalog = false"
        >
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
      <v-snackbar
        v-model="AddCouponDialog" class="arabic"
        :timeout="2000"
          >
           تم إضافة الكوبون بنجاح !
            <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              class="arabic"
              v-bind="attrs"
              @click="AddCouponDialog = false"
          >
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
      <v-snackbar
        v-model="changeCouponTypes" class="arabic"
        :timeout="2000"
          >
           تم تغيير الأنوع بنجاح !
            <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              class="arabic"
              v-bind="attrs"
              @click="changeCouponTypes = false"
          >
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="editstatusEnsure" max-width="600" style="overflow: hidden !important;">
              <v-card
                class="d-flex justify-center align-center black--text arabic"
                width="100%"
                style="background: #F7FBFF;  overflow: hidden !important;"
              >
                <v-row>
                  <v-col cols="12" style="    display: flex;
    align-items: center;
    justify-content: center;">
                    <p class="my-2">
                   هل تريد تعديل حالة الكوبونات المحددة الى {{ this.btnClickedStatus }} ؟
                    </p>
                  </v-col>
                  <v-col class="mb-5" style="    display: flex;
    justify-content: space-evenly;">
                    <v-btn color="#5283A7" class="white--text" @click="editstatusEnsureFun">نعم</v-btn>
                    <v-btn  @click="editstatusEnsure= false">لا</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteCopounEnsure" max-width="600" style="overflow: hidden !important;">
  <v-card
    class="d-flex justify-center align-center black--text arabic"
    width="100%"
    style="background: #fff; overflow: hidden !important;"
  >
    <!-- Alert Content -->
    <v-row class="justify-center align-center">
      <!-- Alert Icon -->
      <v-col cols="12" class="text-center mt-4">
        <v-icon color="red" size="64">mdi-alert-circle-outline</v-icon>
      </v-col>

      <!-- Warning Message -->
      <v-col cols="12" class="text-center">
        <p style="font-size: 1.5rem; font-weight: bold; color: #333;">
          هل أنت متأكد من  حذف  {{ this.selectedCouponRow.length }} كوبونات محددة؟
        </p>
        <p style="color: #666; font-size: 1rem; margin-top: 8px;">
          سيتم حذف الكوبونات المحددة بشكل نهائي ولا يمكن التراجع عن هذا الإجراء.
        </p>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="d-flex justify-center mb-4">
        <v-btn
          color="red"
          dark
          class="mx-2 white--text"
          @click="deleteSelectedCoupons"
        >
          <v-icon left>mdi-trash-can-outline</v-icon>
          نعم، احذف
        </v-btn>
        <v-btn
          color="grey"
          outlined
          class="mx-2"
          @click="deleteCopounEnsure = false"
        >
          <v-icon left>mdi-close-circle-outline</v-icon>
          إلغاء
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</v-dialog>

<v-dialog v-model="deleteCopounEnsureInrow" max-width="600" style="overflow: hidden !important;">
  <v-card
    class="d-flex justify-center align-center black--text arabic"
    width="100%"
    style="background: #fff; overflow: hidden !important;"
  >
    <!-- Alert Content -->
    <v-row class="justify-center align-center">
      <!-- Alert Icon -->
      <v-col cols="12" class="text-center mt-4">
        <v-icon color="red" size="64">mdi-alert-circle-outline</v-icon>
      </v-col>

      <!-- Warning Message -->
      <v-col cols="12" class="text-center">
        <p style="font-size: 1.5rem; font-weight: bold; color: #333;">
          هل أنت متأكد؟
        </p>
        <p style="color: #666; font-size: 1rem; margin-top: 8px;">
          سيتم حذف هذا الكوبون بشكل نهائي ولا يمكن التراجع عن هذا الإجراء.
        </p>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="d-flex justify-center mb-4">
        <v-btn
          color="red"
          dark
          class="mx-2 white--text"
          @click="deleteCoupon1"
        >
          <v-icon left>mdi-trash-can-outline</v-icon>
          نعم، احذف
        </v-btn>
        <v-btn
          color="grey"
          outlined
          class="mx-2"
          @click="deleteCopounEnsureInrow = false"
        >
          <v-icon left>mdi-close-circle-outline</v-icon>
          إلغاء
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</v-dialog>
<v-dialog v-model="logDialog" max-width="600px">
      <v-card max-height="400px">
        <!-- Title -->
        <v-card-title style="background-color: #E0ECF8;">
          <span class="arabic-bold" style="color: #4378a0;">معلومات لوغ كوبون</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="logDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Scrollable Content -->
        <v-card-text class="arabic my-3" style="max-height: 300px; overflow-y: auto; white-space: pre-wrap;">
          <div v-for="(log, index) in LogInfo" :key="index" v-html="log"></div>
          </v-card-text>
      </v-card>
    </v-dialog>
            <!-- <v-dialog v-model="logDialog" max-width="800">
              <v-card height="500"
                class="black--text arabic d-flex flex-column"
                width="100%"
                style="background: #FFFFFF;"
              >
              <v-card-title class="arabic-bold">معلومات لوغ الكوبون</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="my-2" style="white-space: pre-wrap;">
                 <div v-for="(log, index) in LogInfo" :key="index" v-html="log"></div>
              </v-card-text>
              <v-card-actions style="bottom: 20px; position: absolute; left: 20px;">
                <v-btn outlined color="red" @click="logDialog = false">
                  إغلاق
                </v-btn>
              </v-card-actions>
              </v-card>
            </v-dialog> -->
            <!-- تعديل الكوبونات -->
            <v-dialog
              v-model="EditCopounInrow"
              max-width="800"
              style="overflow: hidden !important; font-size: 0.75rem !important"
          >
          <v-card
          class="arabic py-6 px-4"
          color="white"
          >
                    <v-card-title class="arabic-bold">
                        تعديل الكوبون
                      </v-card-title>
                      <v-row dense>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
              label=" كود الكوبون*"
              required
              v-model="editnewCoupon"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
                style="font-size: small"
                color="#4378a0"
                class="arabic"
                label="المتجر"
                :items="stores"
                item-text="name_ar"
                item-value="id"
                v-model="editselectedStore"
                @change="GetStoreCountries(selectedStore)"
              >
              <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchStore"
                    placeholder="Search"
                    @input="searchStorefun"
                    solo
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
             </template>
            </v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
            style="font-size: small"
            color="#4378a0"
              class="arabic"
              label="الشركة"
              :items=companies
                item-text="name_ar"
                item-value="id"
                v-model="editselectedCompany"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
            style="font-size: small"
            color="#4378a0"
              class="arabic"
              label="الدول"
              multiple
              :items=allcountries
              v-model="editselectedCountries"
              item-text="name_ar"
              item-value="id"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
              v-if="this.selectedTap === 'التطبيق'"
            >
              <v-text-field
              color="#4378a0"
              label="عنوان عربي"
              required
              v-model="editar_name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
               v-if="this.selectedTap === 'التطبيق'"
            >
              <v-text-field
              color="#4378a0"
              label="عنوان انجليزي"
              required
              v-model="editen_name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
              v-if="this.selectedTap === 'التطبيق'"
            >
            <v-text-field
              v-model="editdiscount"
               color="#4378a0"
              label="نسبة الخصم"
              type="number"
              min="0"
              max="100"
              suffix="%"
              :disabled="editfree_shipping"
               @blur="setDefaultDiscount"
              class="arabic"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="8"
              sm="6"
            >
              <v-text-field
              color="#4378a0"
                label="تفاصيل الكوبون"
                v-model="editNewCouponDetails"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
               v-if="this.selectedTap === 'التطبيق'"
            >
            <v-switch
              v-model="editfree_shipping"
              label="شحن مجاني؟"
              color="#4378a0"
              class="arabic"
            ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
               v-if="this.selectedTap === 'التطبيق'"
            >
            <v-switch
              v-model="editprize"
              label="قسيمة"
              color="#4378a0"
              class="arabic"
            ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="5"
              sm="6"
            >
            <v-menu
        ref="editmenu"
        v-model="editmenu"
        :close-on-content-click="false"
        :return-value.sync="editdate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="#4378a0"
            v-model="editdate"
            label="تاريخ انتهاء صلاحية الكوبونات"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            color="#4378a0"
          v-model="editdate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            style="letter-spacing: normal;"
            class="arabic"
            color="#4378a0"
            @click="edutmenu = false"
          >
            إغلاق
          </v-btn>
          <v-btn
            style="letter-spacing: normal;"
            class="arabic white--text"
            color="#4378a0"
            @click="$refs.editmenu.save(editdate)"
          >
            حفظ
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
              cols="12"
              md="4"
              sm="6"
            >
            <v-select
              color="#4378a0"
              label="مُستَخدم"
              v-model="editIsUsed"
              :items="isUsedOptions"
              item-text="text"
              item-value="value"
            />
            </v-col>
          </v-row>
          <v-row
                v-for="(row, index) in editnewTypesArray"
                :key="index"
                :style="{
                        'height': $vuetify.breakpoint.smAndDown ? '100%' : '50px',
                    }"
                  class="mx-5 my-0 align-center"
    >
      <v-col cols="12"  md="5">
        <v-select
          :items="editgetAvailableTypes(index)"
          label="الأنواع"
          color="#4378a0"
          class="arabic mx-4"
          required
          v-model="row.name"
          :disabled="isMaxTypesReached && !row.name"
        ></v-select>
      </v-col>

      <v-col cols="12"  md="5">
        <v-select
          :items="['بيدينج', 'قيد العمل', 'متوقف اوتوماتيكي', 'متوقف يدوي', 'قديم', 'جديد']"
          label="الحالة"
          color="#4378a0"
          class="arabic mx-4"
          v-model="row.cases"
        ></v-select>
      </v-col>

      <v-col cols="2" class="text-center">
        <!-- Remove button -->
        <v-btn color="red" @click="editremoveRow(index)" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      color="#4378a0"
      outlined
      class="arabic my-8"
      :disabled="isMaxTypesReached || editnewTypesArray.length >= 6"
      @click="editaddRow"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
                    <v-row class="mx-5">
                      <v-col cols="12" md="6" class="pa-0" style="bottom: 20px;">
                        <v-btn color="success"  @click="Edit()" style="" class="arabic white--text mx-1">
                          تعديل
                        </v-btn>
                        </v-col>
                      <v-col cols="12" md="6" class="pa-0" style="bottom: 20px; position: absolute; left: 20px; display: grid;">
                        <v-btn outlined color="red" @click="EditCopounInrow = false" style="justify-self: end;" class="">
                          إغلاق
                        </v-btn>
                        </v-col>
                    </v-row>
          </v-card>
                   </v-dialog>
                   <v-snackbar
         v-model="CompleteEdit" class="arabic"
          :timeout="2000"
        >
      تم تعديل معلومات الكوبون بنجاح

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              class="arabic"
              v-bind="attrs"
              @click="CompleteEdit = false"
            >
              إغلاق
            </v-btn>
          </template>
        </v-snackbar>
      </div>
</template>

<script>
import axios from 'axios'
import { setTimeout } from 'core-js'
import * as XLSX from 'xlsx'
export default {
  name: 'HelloWorld',
  data () {
    return {
      fabState: {},
      CompleteEdit: false,
      isUsedOptions: [
        { text: 'مستخدم', value: true },
        { text: 'غير مستخدم', value: false }
      ],
      editdate: '',
      editIsUsed: '',
      editNewCouponDetails: '',
      editselectedCountries: [],
      editselectedCompany: '',
      editselectedStore: '',
      editnewCoupon: '',
      EditCopounInrow: false,
      logDialog: false,
      LogInfo: '',
      numberOfPages: null,
      page: 1,
      itemsPerPage: 100,
      itemsPerPageArray: [100, 500, 999],
      nextPageUrl: null,
      prevPageUrl: null,
      editnewTypesArray: [],
      newTypesArray: [
        { update_type: '', update_status: '' }
      ],
      newTypesArrayDefualt: [
        { update_type: 'المحزن', update_status: 'جديد' }
      ],
      edittypes: ['المخزن', 'إضافة الكروم', 'التطبيق', 'يلا كوبون', 'بوابة الكوبونات', 'غوغل ادز'], // List of all possible types
      types: ['المخزن', 'إضافة الكروم', 'التطبيق', 'يلا كوبون', 'بوابة الكوبونات', 'غوغل ادز'], // List of all possible types
      maxTypes: 6,
      filterdID: '',
      filterdCode: '',
      filterdStore: '',
      filterdCompany: '',
      filterdTypes: '',
      filtersStoreStatus: '',
      filteredCoupons: [],
      selectedCaseStatus: 'الكل',
      cases: ['جديد', 'قديم', 'قيد العمل', 'بيدينج', 'متوقف', 'الكوبونات المشتركة'],
      filteredCouponsBycases: [],
      selectedSearchStatus: 'جديد',
      SelectedSearchStores: null,
      selectesSearchType: null,
      SpSearchCoupons: [],
      spSelectedCoupons: [],
      searchStore: '',
      storesBackup: [],
      companiesBackup: [],
      paginationOptions: [25, 100, 500, 1000],
      loading: true,
      deleteDioalog: false,
      editStatusDioalog: false,
      AddCouponDialog: false,
      changeCouponTypes: false,
      fieldsVisible: false,
      filesInSearch: false,
      e6: 1,
      date: null,
      newCoupon: '',
      NewCouponDetails: '',
      dialog: false,
      dialog1: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      selectedTap: 'كل الكوبونات',
      selectedButton: 0,
      searchT: '',
      couponsCounter: 0,
      storesCounter: 0,
      companiesCounter: 0,
      selectedCouponRow: [],
      NewAddedCoupons: [],
      NewAddedCouponsId: null,
      addedCouponsType: 'المخزن',
      addedCouponsStatus: 'جديد',
      addedCouponsNewStatusAndType: [],
      stores: [],
      allcountries: [],
      countries: [],
      companies: [],
      companiesDisplay: [],
      selectedStore: null,
      selectedStoreCountries: {
        store_id: this.selectedStore
      },
      selectedCountries: [],
      ar_name: 'كوبون الخصم',
      en_name: 'Discount Coupon',
      discount: 0,
      free_shipping: false,
      prize: false,
      editar_name: '',
      editen_name: '',
      editdiscount: 0,
      editfree_shipping: false,
      editprize: false,
      allCoupons: [],
      selectedCompany: null,
      singleSelect: false,
      editstatusEnsure: false,
      deleteCopounEnsure: false,
      btnClickedStatus: null,
      YES: false,
      statusButtons: [
        {
          text: 'متوقف يدوي',
          color: 'red',
          icon: 'mdi-stop',
          onClick: this.editStatusStop
        },
        {
          text: 'جديد',
          color: 'green',
          icon: 'mdi-new-box',
          onClick: null // Add a method here if needed
        },
        {
          text: 'قديم',
          color: 'lime darken-4',
          icon: 'mdi-delete-variant',
          onClick: null // Add a method here if needed
        },
        {
          text: 'بيدينج',
          color: 'orange darken-2',
          icon: 'mdi-clock-time-four',
          onClick: null // Add a method here if needed
        },
        {
          text: 'قيد العمل',
          color: '#4378a0',
          icon: 'mdi-lightbulb-on-90',
          onClick: null // Add a method here if needed
        }
      ],
      headers: [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'الكود', value: 'code', align: 'center' },
        { text: 'المتجر', value: 'store.name_ar', align: 'center' },
        { text: 'الشركة', value: 'company', align: 'center' },
        { text: 'الحالة', value: 'allCases', align: 'center' },
        { text: 'النوع', value: 'types', align: 'center' },
        { text: 'التفاصيل', value: 'details', align: 'center' },
        { text: 'الانتقال', value: 'moved_to_in_progress_status', align: 'center' },
        { text: 'مستخدم', value: 'is_used', align: 'center' },
        { text: 'الإضافة', value: 'created_at', align: 'center' },
        { text: 'عنوان', value: 'ar_name', align: 'center' },
        { text: 'عنوان EN', value: 'en_name', align: 'center' },
        { text: 'الخصم', value: 'discount', align: 'center' },
        { text: 'شحن', value: 'free_shipping', align: 'center' },
        { text: 'قسيمة', value: 'prize', align: 'center' },
        { text: 'الإجراءات', value: 'actions', align: 'center' }
      ],
      coupons: [],
      columns: [
        { text: 'ID', value: 'id' },
        { text: 'الكود', value: 'code' },
        { text: 'المتجر', value: 'store.name_ar' },
        { text: 'الشركة', value: 'company' },
        { text: 'الحالة', value: 'allCases' },
        { text: 'النوع', value: 'types' },
        { text: 'التفاصيل', value: 'details' },
        { text: 'الانتقال', value: 'moved_to_in_progress_status' },
        { text: 'الإضافة', value: 'created_at' },
        { text: 'مستخدم', value: 'is_used' },
        { text: 'عنوان', value: 'ar_name' },
        { text: 'عنوان E', value: 'en_name' },
        { text: 'الخصم', value: 'discount' },
        { text: 'شحن', value: 'free_shipping' },
        { text: 'قسيمة', value: 'prize' },
        { text: 'الإجراءات', value: 'actions' }
      ],
      selectedCoupons: [{ text: 'ID', value: 'id', align: 'center' },
        { text: 'الكود', value: 'code', align: 'center' },
        { text: 'المتجر', value: 'store.name_ar', align: 'center' },
        { text: 'الشركة', value: 'company', align: 'center' },
        { text: 'الحالة', value: 'allCases', align: 'center' },
        { text: 'النوع', value: 'types', align: 'center' },
        { text: 'التفاصيل', value: 'details', align: 'center' },
        { text: 'الانتقال', value: 'moved_to_in_progress_status', align: 'center' },
        { text: 'مستخدم', value: 'is_used', align: 'center' },
        { text: 'الإضافة', value: 'created_at', align: 'center' },
        { text: 'عنوان', value: 'ar_name', align: 'center' },
        { text: 'عنوان E', value: 'en_name', align: 'center' },
        { text: 'الخصم', value: 'discount', align: 'center' },
        { text: 'شحن', value: 'free_shipping', align: 'center' },
        { text: 'قسيمة', value: 'prize', align: 'center' },
        { text: 'الإجراءات', value: 'actions', align: 'center' }],
      pickedDate: null,
      buttons: [
        { label: 'كل الكوبونات', value: 'كل الكوبونات', icon: 'mdi-ticket-percent', api: 'https://www.teebtop.com/dashboard/api_get_all_coupons' },
        { label: 'غوغل ادز', value: 'غوغل ادز', icon: 'mdi-google-ads', api: 'https://www.teebtop.com/dashboard/get-all-coupons-googleAds' },
        { label: 'بوابة الكوبونات', value: 'بوابة الكوبونات', icon: 'mdi-gate', api: 'https://www.teebtop.com/dashboard/get-all-coupons-website' },
        { label: 'يلا كوبون', value: 'يلا كوبون', icon: 'mdi-percent-outline', api: 'https://www.teebtop.com/dashboard/get-all-coupons-yalla' },
        { label: 'إضافة الكروم', value: 'إضافة الكروم', icon: 'mdi-puzzle-outline', api: 'https://www.teebtop.com/dashboard/get-all-coupons-extention' },
        { label: 'التطبيق', value: 'التطبيق', icon: 'mdi-cellphone-screenshot', api: 'https://www.teebtop.com/dashboard/get-all-coupons-app' },
        { label: 'المخزن', value: 'المخزن', icon: 'mdi-database-check', api: 'https://www.teebtop.com/dashboard/get-all-coupons-storage' }
      ],
      search: '',
      EditCopounId: '',
      searchCompanies: '',
      formValid: false,
      serverItems: [],
      totalItems: 0,
      selectedApi: '',
      deleteFromAction: null,
      deleteFromActionId: '',
      pageSize: 100,
      deleteCopounEnsureInrow: null
    }
  },
  methods: {
    setDefaultDiscount () {
      if (!this.discount) {
        this.discount = 0
      }
      if (!this.editdiscount) {
        this.editdiscount = 0
      }
    },
    exportToExcel () {
      // Step 1: Convert table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(this.filteredCouponsBycases)

      // Step 2: Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Coupons')

      // Step 3: Trigger the file download
      XLSX.writeFile(workbook, 'Coupons.xlsx')
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
      this.fetchAllCoupons()
    },
    searchCompanyfun () {
      if (!this.searchCompanies) {
        this.companiesDisplay = [...this.companiesBackup]
      } else {
        this.companiesDisplay = this.companiesBackup.filter(company => {
          return company.name_ar.toLowerCase().includes(this.searchCompanies.toLowerCase())
        })
      }
    },
    getStoreByCompany () {
      const FormData = require('form-data')
      const data = new FormData()
      data.append('company_id', this.filterdCompany)
      data.append('cases', this.selectedCaseStatus)
      // console.log('company_id', this.filterdCompany)
      // console.log('cases', this.selectedCaseStatus)
      try {
        if (this.filterdCompany) {
          axios.post('https://www.teebtop.com/dashboard/api_get_store_by_company_status', data)
            .then(response => {
              console.log(response.data)
              this.stores = response.data.store
            })
            .catch(error => {
              console.error('Error data:', error.response ? error.response.data : error.message)
            })
        } else {
          this.stores = this.$store.state.storesBackup
        }
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    },
    getCompanyByStore () {
      const FormData = require('form-data')
      const data = new FormData()
      data.append('store_id', this.filterdStore)
      data.append('cases', this.selectedCaseStatus)
      try {
        if (this.filterdStore) {
          axios.post('https://www.teebtop.com/dashboard/api_get_company_by_store_status', data)
            .then(response => {
              this.companiesDisplay = response.data.company
            })
            .catch(error => {
              console.error('Error data:', error.response ? error.response.data : error.message)
            })
        } else {
          this.companiesDisplay = this.$store.state.companies
        }
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    },
    formatDate (date) {
      if (!date) return { date: '-', time: '-' } // Handle missing or null date

      const parsedDate = new Date(date)

      // Check if the date is valid
      if (isNaN(parsedDate.getTime())) {
        return { date: '-', time: '-' } // Handle invalid date format
      }

      const optionsDate = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }

      const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }

      const formattedDate = parsedDate.toLocaleDateString('en-GB', optionsDate)
      const formattedTime = parsedDate.toLocaleTimeString('en-GB', optionsTime)

      return {
        date: formattedDate,
        time: formattedTime
      }
    },
    getStatusColor (status) {
      switch (status) {
        case 'متوقف':
          return 'red'
        case 'متوقف اوتوماتيكي':
          return 'red'
        case 'جديد':
          return 'green'
        case 'قديم':
          return 'lime darken-4'
        case 'بيدينج':
          return 'orange darken-2'
        case 'قيد العمل':
          return '#4378a0'
        case null:
          return null
      }
    },
    logCoupon (couponID) {
      try {
        axios.get(`https://www.teebtop.com/dashboard/api_log_coupons/${couponID}`).then((response) => {
          this.logDialog = true
          this.LogInfo = response.data.map(entry => {
            return entry.replace(/[\n]/g, '<br>')
              .replace(/\*+/g, '<hr class="my-1">')
              .replace(/في التاريخ: (\d{4}-\d{2}-\d{2})/g, 'في التاريخ: <span class="date">$1</span>') // Style the date
              .replace(/تم (إضافة|تعديل) الكوبون: \(([^)]+)\)/g, 'تم <span class="action">$1</span> الكوبون: <span class="coupon-code">($2)</span>') // Style action and coupon code
              .replace(/متجر: ([^,]+)/g, 'متجر: <span class="store">$1</span>') // Style the store
              .replace(/شركة: ([^,]+)/g, 'شركة: <span class="company">$1</span>') // Style the company
              .replace(/بحالة: \[(.*?)]/g, 'بحالة: <span class="status">$1</span>')
          })
          console.log(response)
        })
      } catch (error) {
        console.log('Failed:', error)
      }
    },
    getEditCopoun (copounID) {
      axios.get(`https://www.teebtop.com/dashboard/api_edit_coupons/${copounID}`)
        .then(response => {
          this.EditCopounId = copounID
          this.editnewCoupon = response.data.code
          this.editselectedStore = response.data.store
          this.editselectedCompany = response.data.company
          this.editselectedCountries = response.data.country
          this.editNewCouponDetails = response.data.details
          this.editIsUsed = response.data.is_used
          this.editdate = response.data.expiration_date
          this.editnewTypesArray = response.data.types
          this.editar_name = response.data.ar_name
          this.editen_name = response.data.en_name
          this.editdiscount = response.data.discount
          this.editfree_shipping = response.data.free_shipping
          this.editprize = response.data.prize
          this.EditCopounInrow = true
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    Edit () {
      const data = {
        code: this.editnewCoupon,
        store: this.editselectedStore,
        country: this.editselectedCountries,
        company: this.editselectedCompany,
        is_used: this.editIsUsed,
        details: this.editNewCouponDetails,
        type: this.editnewTypesArray,
        ar_name: this.editar_name,
        en_name: this.editen_name,
        discount: this.editdiscount,
        prize: this.editprize,
        free_shipping: this.editfree_shipping,
        expiration_date: this.editdate
      }
      axios.post(`https://www.teebtop.com/dashboard/api_edit_coupon_data/${this.EditCopounId}`, data, {
      })
        .then(response => {
          console.log('Response:', response.data)
          this.EditCopounInrow = false
          this.CompleteEdit = true
          this.fetchAllCoupons()
        })
        .catch(error => {
          console.error('Error data:', error.response ? error.response.data : error.message)
        })
    },
    deleteCoupon (couponId) {
      this.deleteCopounEnsureInrow = true
      this.deleteFromActionId = couponId
    },
    deleteCoupon1 () {
      const FormData = require('form-data')
      const data = new FormData()
      if (this.selectedTap === 'كل الكوبونات') {
        data.append('type', 'all')
      } else {
        data.append('type', this.selectedTap)
      }
      console.log(this.selectedTap)
      try {
        axios.post(`https://www.teebtop.com/dashboard/api_coupon_delete/${this.deleteFromActionId}`, data).then((response) => {
          console.log(response)
          this.fetchAllCoupons()
          setTimeout(() => {
            this.deleteDioalog = true
            this.deleteCopounEnsureInrow = false
          }, 2000)
        })
      } catch (error) {
        console.log('Failed:', error)
      }
    },
    changeCouponsTypeStatus () {
      const data = {
        ids: this.spSelectedCoupons.map(coupon => coupon).join(' ').toString(),
        type: this.newTypesArray
      }
      axios.post('https://www.teebtop.com/dashboard/api_change_type_coupons', data)
        .then(response => {
          console.log('Response:', response.data)
          this.changeCouponTypes = true
          this.fetchAllCoupons()
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    getAvailableTypes (index) {
      const selectedTypes = this.newTypesArray.map(row => row.update_type).filter(Boolean)
      return this.types.filter(type => selectedTypes.indexOf(type) === -1 || this.newTypesArray[index].update_type === type)
    },
    editgetAvailableTypes (index) {
      const selectedTypes = this.editnewTypesArray.map(row => row.name).filter(Boolean)
      return this.edittypes.filter(type => selectedTypes.indexOf(type) === -1 || this.editnewTypesArray[index].name === type)
    },
    // in change type an status
    addRow () {
      if (this.totalSelectedTypes < this.maxTypes && this.newTypesArray.length < 6) {
        this.newTypesArray.push({ update_type: '', update_status: '' })
      }
    },
    editaddRow () {
      if (this.totalSelectedTypes < this.maxTypes && this.editnewTypesArray.length < 6) {
        this.editnewTypesArray.push({ name: '', cases: '' })
      }
    },
    // Method to remove a row  in change type an status
    removeRow (index) {
      this.newTypesArray.splice(index, 1)
    },
    editremoveRow (index) {
      this.editnewTypesArray.splice(index, 1)
    },
    filterByCaseStatus (status) {
      this.selectedCaseStatus = status
    },
    filterCoupons () {
      this.SpSearchCoupons = this.coupons.filter(coupon => {
        const matchesStatus = coupon.types[0].cases === this.selectedSearchStatus
        const matchesStore = this.SelectedSearchStores
          ? coupon.store.name_ar === this.SelectedSearchStores
          : true
        const matchesType = this.selectesSearchType
          ? coupon.types[0].name === this.selectesSearchType
          : true
        return matchesStatus && matchesStore && matchesType
      })
    },
    searchStorefun () {
      if (!this.searchStore) {
        this.stores = [...this.storesBackup]
      } else {
        this.stores = this.storesBackup.filter(store => {
          return store.name_ar.toLowerCase().includes(this.searchStore.toLowerCase())
        })
      }
    },
    addNewCoupon () {
      const FormData = require('form-data')
      const data = new FormData()
      data.append('company', this.selectedCompany)
      data.append('store', this.selectedStore)
      this.selectedCountries.forEach(element => {
        data.append('country', element)
      })
      data.append('code', this.newCoupon)
      data.append('ar_name', this.ar_name)
      data.append('en_name', this.en_name)
      data.append('free_shipping', this.free_shipping)
      data.append('discount', this.discount)
      data.append('prize', this.prize)
      data.append('details', this.NewCouponDetails)
      if (this.date !== null) {
        data.append('expiration_date', this.date)
      }

      axios.post('https://www.teebtop.com/dashboard/api_coupons', data)
        .then(response => {
          this.e6 = 2
          console.log('Response:', response.data)
          this.NewAddedCoupons = response.data.coupons
          this.NewAddedCouponsId = this.NewAddedCoupons.map(item => item.id).join(' ')
          this.NewCouponDetails = ''
          this.selectedCountries = ''
          this.selectedCompany = ''
          this.searchStore = ''
          this.selectedStore = ''
          this.newCoupon = ''
          this.prize = false
          this.free_shipping = false
          this.discount = 0
          this.en_name = ''
          this.ar_name = ''
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },

    changeNewCouponsTypeStatus () {
      this.couponStepperFinish()
      this.AddCouponDialog = true
      if (this.newTypesArray[0].update_type === '') {
        this.newTypesArray = this.newTypesArrayDefualt
      }
      const data = {
        ids: this.NewAddedCoupons.map(item => item.id).join(' ').toString(),
        type: this.newTypesArray
      }
      axios.post('https://www.teebtop.com/dashboard/api_change_type_coupons', data)
        .then(response => {
          console.log('Response:', response.data)
          this.fetchAllCoupons()
          this.NewAddedCoupons = []
          this.newTypesArray = [{ update_type: '', update_status: '' }]
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    deleteSelectedCoupons () {
      this.deleteCopounEnsure = false
      const FormData = require('form-data')
      const ids = this.selectedCouponRow.map(item => item.id).join(' ').toString()
      const data = new FormData()
      data.append('table', 'Coupon')
      data.append('ids', ids)
      data.append('type', 'all')

      axios.post('https://www.teebtop.com/dashboard/api_delete_multi_select', data)
        .then(response => {
          console.log('Response:', response.data)
          this.fetchAllCoupons()
          this.deleteDioalog = true
          this.selectedCouponRow = []
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    selectButton (value, index) {
      this.selectedTap = value
      this.selectedButton = index
    },
    couponStepperFinish () {
      this.e6 = 1
      this.dialog = false
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllCoupons) {
          this.selectedCoupons = []
        } else {
          this.selectedCoupons = this.columns.slice()
        }
      })
    },
    toggleFields () {
      this.fieldsVisible = !this.fieldsVisible
    },
    async fetchAllCoupons (pageUrl = null) {
      this.loading = true
      const FormData = require('form-data')
      const data = new FormData()
      data.append('page_size', this.itemsPerPage)
      data.append('tab', this.selectedTap)
      data.append('cases', this.selectedCaseStatus)
      if (this.filterdID) {
        data.append('ID', this.filterdID)
      }
      if (this.filterdCode) {
        data.append('code', this.filterdCode)
      }
      if (this.filterdStore) {
        data.append('store', this.filterdStore)
      }
      if (this.filterdCompany) {
        data.append('company', this.filterdCompany)
      }
      if (this.filterdTypes) {
        data.append('type', this.filterdTypes)
      }
      if (this.filtersStoreStatus) {
        data.append('store_case', this.filtersStoreStatus)
      }
      console.log(`Bearer ${this.$store.state.token}`)
      const apiUrl = pageUrl || 'https://www.teebtop.com/dashboard/api_get_coupons?page=1'
      try {
        const response = await axios.post(apiUrl, data)
        if (!pageUrl) {
          this.coupons = response.data.results.coupons
          console.log(response.data)
          // console.log(this.coupons)
          this.coupons.forEach(element => {
            if (element.types.length === 0) {
              console.log(element.code, element.types.length)
            }
          })
          this.filteredCouponsBycases = this.coupons.map(coupon => {
            return {
              ...coupon,
              allCases: coupon.types.map(type => type.cases).join(', ') // Join all cases together as a string
            }
          })
          this.filteredCoupons = [...this.coupons]

          // Update counters
          this.couponsCounter = response.data.results.coupon_count
          this.storesCounter = response.data.results.store_count
          this.companiesCounter = response.data.results.company_count
          this.numberOfPages = Math.ceil(this.couponsCounter / this.itemsPerPage)
          this.nextPageUrl = response.data.next
          this.prevPageUrl = response.data.previous
          if (this.prevPageUrl === null) {
            this.prevPageUrl = `https://www.teebtop.com/dashboard/api_get_coupons?page=${this.numberOfPages}`
          }
          if (this.nextPageUrl === null) {
            this.nextPageUrl = 'https://www.teebtop.com/dashboard/api_get_coupons?page=1'
          }
          // console.log('First 100 coupons displayed:', response.data)
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Failed to fetch coupons:', error.response.data)
        } else if (error.request) {
          // Request was made but no response received
          console.error('No response received:', error.request)
        } else {
          // Something else happened
          console.error('Error:', error.message)
        }
      } finally {
        this.loading = false
      }
    },
    async nextPage () {
      if (this.nextPageUrl) {
        this.loading = true
        const FormData = require('form-data')
        const data = new FormData()
        data.append('page_size', this.itemsPerPage)
        data.append('tab', this.selectedTap)
        data.append('cases', this.selectedCaseStatus)
        try {
          // Fetch the next page using the stored nextPageUrl
          const response = await axios.post(this.nextPageUrl, data)
          console.log(response.data)
          // Append new coupons to the existing list
          this.coupons = response.data.results.coupons
          this.filteredCouponsBycases = [...this.coupons]
          this.filteredCoupons = [...this.coupons]

          // Update the nextPageUrl based on the response (or set to null if no more pages)
          this.nextPageUrl = response.data.next
          this.prevPageUrl = response.data.previous
          if (this.prevPageUrl === null) {
            this.prevPageUrl = `https://www.teebtop.com/dashboard/api_get_coupons?page=${this.numberOfPages}`
          }
          if (this.nextPageUrl === null) {
            this.nextPageUrl = 'https://www.teebtop.com/dashboard/api_get_coupons?page=1'
          }
          // Increment the current page number
          if (this.page === this.numberOfPages) {
            this.page = 1
          } else { this.page++ }
        } catch (error) {
          console.error('Error fetching next page:', error)
        } finally {
          this.loading = false
        }
      }
    },
    async formerPage () {
      if (this.prevPageUrl) {
        this.loading = true
        const FormData = require('form-data')
        const data = new FormData()
        data.append('page_size', this.itemsPerPage)
        data.append('tab', this.selectedTap)
        data.append('cases', this.selectedCaseStatus)

        try {
          // Fetch the next page using the stored nextPageUrl
          const response = await axios.post(this.prevPageUrl, data)
          console.log(response.data)
          // Append new coupons to the existing list
          this.coupons = response.data.results.coupons
          this.filteredCouponsBycases = [...this.coupons]
          this.filteredCoupons = [...this.coupons]

          // Update the nextPageUrl based on the response (or set to null if no more pages)
          this.nextPageUrl = response.data.next
          this.prevPageUrl = response.data.previous
          if (this.prevPageUrl === null) {
            this.prevPageUrl = `https://www.teebtop.com/dashboard/api_get_coupons?page=${this.numberOfPages}`
          }
          if (this.nextPageUrl === null) {
            this.nextPageUrl = 'https://www.teebtop.com/dashboard/api_get_coupons?page=1'
          }
          // Increment the current page number
          if (this.page === 1) {
            this.page = this.numberOfPages
          } else { this.page-- }
        } catch (error) {
          console.error('Error fetching previous page:', error)
        } finally {
          this.loading = false
        }
      }
    },
    async editStatusStop (status) {
      this.editstatusEnsure = true
      this.btnClickedStatus = status
    },
    async editstatusEnsureFun () {
      this.editstatusEnsure = false
      const FormData = require('form-data')
      const ids = this.selectedCouponRow.map(item => item.id).join(' ').toString()
      const data = new FormData()
      data.append('table', 'Coupon')
      data.append('status', this.btnClickedStatus)
      data.append('ids', ids)
      if (this.selectedButton === 1) {
        data.append('type', 'غوغل ادز')
      } else if (this.selectedButton === 2) {
        data.append('type', 'بوابة الكوبونات')
      } else if (this.selectedButton === 3) {
        data.append('type', 'يلا كوبون')
      } else if (this.selectedButton === 4) {
        data.append('type', 'إضافة الكروم')
      } else if (this.selectedButton === 5) {
        data.append('type', 'التطبيق')
      } else if (this.selectedButton === 6) {
        data.append('type', 'المخزن')
      } else if (this.selectedButton === 0) {
        data.append('type', 'غوغل ادز,بوابة الكوبونات,إضافة الكروم,يلا كوبون,التطبيق,المخزن')
      }
      axios.post('https://www.teebtop.com/dashboard/api_change_status_multi_select', data)
        .then(response => {
          console.log('Response:', response.data)
          this.fetchAllCoupons()
          this.editStatusDioalog = true
          setTimeout(() => {
            this.editStatusDioalog = false
            this.selectedCouponRow = []
          }, 3000)
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    async GetContriesNstoresNcompany () {
      try {
        const response = await axios.get('https://www.teebtop.com/dashboard/api_coupons')
        this.stores = response.data.stores
        this.companies = response.data.companies
        this.allcountries = response.data.countries
        this.companiesDisplay = this.companies
      } catch (error) {
        console.error('error:', error)
      }
    },
    async GetStoreCountries (selectedStore) {
      const data = {
        store_id: this.selectedStore
      }
      axios.post('https://www.teebtop.com/dashboard/api_get_store_country', data)
        .then(response => {
          this.countries = response.data.countries
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    },
    handleFilterChange (type, value) {
      console.log(`Filtering by ${type}:`, value)
      // Add your logic to save or filter based on the input change
      this.fetchAllCoupons()
    }
    // async fetchData (api, index) {
    //   this.selectedButton = index
    //   this.loading = true
    //   try {
    //     await axios.get(api).then((response) => {
    //       this.couponsCounter = response.data.length
    //       this.coupons = null
    //       this.coupons = response.data
    //       this.loading = false
    //     })
    //   } catch (error) {
    //     console.log('Failed to fetch Google Ads coupons:', error)
    //   }
    // }
  },
  computed: {
    totalSelectedTypes () {
      return this.newTypesArray.filter(row => row.update_type).length
    },
    // Check if the max number of types is selected
    isMaxTypesReached () {
      return this.totalSelectedTypes >= this.maxTypes
    },
    likesAllCoupons () {
      return this.selectedCoupons.length === this.columns.length
    },
    likesSomeCoupons () {
      return this.selectedCoupons.length > 0 && !this.likesAllCoupons
    },
    icon () {
      if (this.likesAllCoupons) return 'mdi-close-box'
      if (this.likesSomeCoupons) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  created () {
    this.$vuetify.rtl = true
  },
  mounted () {
    this.fetchAllCoupons()
    this.selectedCoupons = this.headers.filter(
      header => !['created_at', 'ar_name', 'en_name', 'free_shipping', 'prize', 'discount'].includes(header.value)
    )
    this.$store.dispatch('GetContriesNstoresNcompany')
    this.GetContriesNstoresNcompany()
  },
  watch: {
    free_shipping (newValue) {
      if (newValue) {
        this.discount = 0 // Set discount to 0 when free_shipping is true
      }
    },
    editfree_shipping (newValue) {
      if (newValue) {
        this.editdiscount = 0 // Set discount to 0 when free_shipping is true
      }
    },
    stores (newStores) {
      if (newStores.length && !this.storesBackup.length) {
        this.storesBackup = [...newStores]
      }
    },
    companies (newCompany) {
      if (newCompany.length && !this.companiesBackup.length) {
        this.companiesBackup = [...newCompany]
      }
    },
    filterdID (newVal) {
      this.handleFilterChange('ID', newVal)
    },
    filterdCode (newVal) {
      this.handleFilterChange('Code', newVal)
    },
    filterdStore (newVal) {
      this.handleFilterChange('Store', newVal)
    },
    filterdCompany (newVal) {
      this.handleFilterChange('Company', newVal)
    },
    filterdTypes (newVal) {
      this.handleFilterChange('Type', newVal)
    },
    filtersStoreStatus (newVal) {
      this.handleFilterChange('Store Status', newVal)
    }
    // selectedCoupons (newSelected) {
    // // Maintain the original order of the columns based on the `headers` array
    //   this.selectedCoupons = this.headers.filter(header =>
    //     newSelected.some(selected => selected.value === header.value)
    //   )
    // }
  }
}

</script>
<style scoped>
::v-deep(.v-chip) {
  margin-bottom: 5px !important;
}
::v-deep(.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th) {
  font-size: small !important;
  color: #006b96b1 !important;
  font-weight: 600 !important;
  align-self: center !important;
}
</style>
<style>

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon{
  display: block !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(4 4 4 / 12%) !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: medium !important;
  color: #066b96b1;
  font-weight: 600 !important;
  align-self: center !important;
}
.v-card__underlay{
  color: white;
}
.v-field__field {
    justify-content: flex-end;
    background-color: transparent;
}
.v-field--variant-filled .v-field__overlay {
  background-color: white;
}
.v-btn {
  position: relative;
}
.active-button .v-btn__content{
  color: #4378a0;
}
.BtnUnderline {
  background-color: #4378a0 !important;
  border-color:#4378a0 !important;
  bottom: -14px !important;
}
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
  background-color: transparent;
}
.dotted-border-card1 {
  border: 2px dashed #4378a0  !important; /* Adjust color and thickness as needed */
  border-radius: 8px; /* Optional: Add some border-radius for rounded corners */
}
.dotted-border-card2 {
  box-shadow: 0;
  border: 2px dashed #4378a0 !important ; /* Adjust color and thickness as needed */
  border-radius: 8px; /* Optional: Add some border-radius for rounded corners */
}
.dotted-border-card3 {
  border: 2px dashed #4378a0  !important;
  border-radius: 8px;
}
.v-list-item__title{
  font-family: cairo;
}
.rotate {
  -webkit-animation: rotate 10s normal linear infinite;
  animation: rotate 10s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.5s ease;
}
.slide-down-enter, .slide-down-leave-to /* .slide-down-leave-active في الإصدارات القديمة من Vue */ {
  transform: translateY(-20px);
  opacity: 0;
}
.v-snack__wrapper.theme--dark {
    background-color: #68a39c;
    color: rgb(255 255 255 / 87%);
}
.v-data-table tbody tr:nth-child(even) {
  background-color: #f7fbffe5; /* Light gray for even rows */
}

.v-data-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}
@media (min-width: 960px) {
  .v-data-table__wrapper table th,
  .v-data-table__wrapper table td {
    max-width: 150px; /* Set the maximum width */
    overflow: hidden; /* Hide content that overflows */
    text-overflow: ellipsis; /* Add ellipsis (...) to overflowing text */
  }
}

.date {
  color: #407399;
  font-weight: bold;
}

.action {
  color: #FF5733;
  font-style: italic;
}

.coupon-code {
  color: #407399;
  font-weight: bold;
}

.status {
  color: #407399;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: bold;
}

</style>
